// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // restAPI: 'https://localhost:44357/'
  //restAPI: 'https://localhost:44356/'
  //restAPI: 'https://localhost:5003/'
  //restAPI: 'https://slbteleapi3.idutto2.com/'
  //restAPI: 'https://idativosteleapi.idutto2.com/'
  //restAPI: 'https://localhost:5001/'
  restAPI: 'https://ossteleapi2.idutto2.com/'
  //restAPI: 'https://ossteleeapi3.idutto2.com/'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
